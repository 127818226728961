import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/How_To_Clear_Your_Browsing_History/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Erase your Browsing History in Mozilla Firefox",
  "path": "/Quick_Installation/How_To_Clear_Your_Browsing_History/Mozilla_Firefox/",
  "dateChanged": "2017-11-24",
  "author": "Mike Polinowski",
  "excerpt": "Erase your Browsing History in Mozilla Firefox",
  "image": "./QI_SearchThumb_ClearBrowsingHistory_Firefox.png",
  "social": "/images/Search/QI_SearchThumb_ClearBrowsingHistory_Firefox.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Clear_History_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Clear your Browser History' dateChanged='2017-11-24' author='Mike Polinowski' tag='INSTAR IP Camera' description='Clear your Browser History' image='/images/Search/QI_SearchThumb_ClearBrowsingHistory.png' twitter='/images/Search/QI_SearchThumb_ClearBrowsingHistory.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Leeren_des_Browserverlaufs/Mozilla_Firefox/' locationFR='/fr/Quick_Installation/How_To_Clear_Your_Browsing_History/Mozilla_Firefox/' crumbLabel="Firefox" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "erase-your-browsing-history-in-mozilla-firefox",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#erase-your-browsing-history-in-mozilla-firefox",
        "aria-label": "erase your browsing history in mozilla firefox permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Erase your Browsing History in Mozilla Firefox`}</h1>
    <p>{`Sometimes your camera´s WebUI might not behave the way you want. You updated your camera´s software, but it still shows you the old version? An update should bring new functions to the user interface - but they are nowhere to be seen? Or you simply cannot change a setting? Your Browser might have cached old parts of the user interface and refuses to reload the new or updated files. Here is how you can get rid of your browsers browsing history in several common browsers:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9848cd42ef1c7b911989ad9ae15fbb43/5a190/Firefox-Clean-History_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABCklEQVQY03WOzUrDUBBG8z4+hxvfQ3DvO/gICoJod0IR3PgEFVxJN7qwglJ/2iQ3ubkz38xNeiWJpIEiNT3LGQ7fiUCW2RExAPkL4JkFLN0DRARGWZZ1XXv13pcAIh6GoH7BVc4QyOYqIqp+cxmSiciH6uXh5PL86G5auXeCMlMvM4OJdc2Q7ByFBpPb0f7e8eGBfE1YA5PrZRF1jsC8M1sA53h2dTofn3UhEFoXqWoI4XsZL7NcRLZlACbLY0viFT8Nh7Ys9ebZ3b9R0G65atvV7FWux03bRv822RT0NE/SJP5cpJkx1tost44IQGbMRxIXj9PiYmRX1c7sLfooa20SJ3lRZERpan4BBNu7ruXmSRQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9848cd42ef1c7b911989ad9ae15fbb43/e4706/Firefox-Clean-History_01.avif 230w", "/en/static/9848cd42ef1c7b911989ad9ae15fbb43/d1af7/Firefox-Clean-History_01.avif 460w", "/en/static/9848cd42ef1c7b911989ad9ae15fbb43/c9875/Firefox-Clean-History_01.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9848cd42ef1c7b911989ad9ae15fbb43/a0b58/Firefox-Clean-History_01.webp 230w", "/en/static/9848cd42ef1c7b911989ad9ae15fbb43/bc10c/Firefox-Clean-History_01.webp 460w", "/en/static/9848cd42ef1c7b911989ad9ae15fbb43/d00b9/Firefox-Clean-History_01.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9848cd42ef1c7b911989ad9ae15fbb43/81c8e/Firefox-Clean-History_01.png 230w", "/en/static/9848cd42ef1c7b911989ad9ae15fbb43/08a84/Firefox-Clean-History_01.png 460w", "/en/static/9848cd42ef1c7b911989ad9ae15fbb43/5a190/Firefox-Clean-History_01.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9848cd42ef1c7b911989ad9ae15fbb43/5a190/Firefox-Clean-History_01.png",
              "alt": "Erase your Browsing History in Mozilla Firefox",
              "title": "Erase your Browsing History in Mozilla Firefox",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the Settings menu and choose Options.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1700bccbf02b3ed5fa91947e11193d97/5a190/Firefox-Clean-History_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.565217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA0UlEQVQY03XNu07DQBSEYb//A0WioEAIiMBIVIkSiRh7b7N79py90qPEDRbi6/+ZAXpy1gS6iswAjDEAnHMAZmUWpaw1Wht4zyKROYSgtVkWNbSSay2llHYjIhSIWeBDZD59hc9pDt5aEIv03ltrtdacM8U41C1ivmgbAIJl8iLMklJKkYiZ13LVWtvGrbFz8+H0csT+HI/2O+eykuvMJq61/opLKb1npS5397vn824/PXwoeO/hARCRiPwf30hK9m3E0yON7/F1FIqS0nr79/kHTI9KmR8IOS8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1700bccbf02b3ed5fa91947e11193d97/e4706/Firefox-Clean-History_02.avif 230w", "/en/static/1700bccbf02b3ed5fa91947e11193d97/d1af7/Firefox-Clean-History_02.avif 460w", "/en/static/1700bccbf02b3ed5fa91947e11193d97/c9875/Firefox-Clean-History_02.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1700bccbf02b3ed5fa91947e11193d97/a0b58/Firefox-Clean-History_02.webp 230w", "/en/static/1700bccbf02b3ed5fa91947e11193d97/bc10c/Firefox-Clean-History_02.webp 460w", "/en/static/1700bccbf02b3ed5fa91947e11193d97/d00b9/Firefox-Clean-History_02.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1700bccbf02b3ed5fa91947e11193d97/81c8e/Firefox-Clean-History_02.png 230w", "/en/static/1700bccbf02b3ed5fa91947e11193d97/08a84/Firefox-Clean-History_02.png 460w", "/en/static/1700bccbf02b3ed5fa91947e11193d97/5a190/Firefox-Clean-History_02.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1700bccbf02b3ed5fa91947e11193d97/5a190/Firefox-Clean-History_02.png",
              "alt": "Erase your Browsing History in Mozilla Firefox",
              "title": "Erase your Browsing History in Mozilla Firefox",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go to the Privacy Tab and choose to never remember history. Then click on clear all current History.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f90a224680a47323e8895cf697faecd6/5a190/Firefox-Clean-History_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA/0lEQVQY042QXVPCMBBF+f8/zQcdPiqKlKZNk2x2U7JJaGspiGMdcEYf5Mx93HPvzM6srqxFAFtNrLLsK6vVcsrD42KxnM8Xyyx7llfyfLfZbNbrl1mKHGNMKbVtm1JCROccWgREDfS0rrQSyoBFaq/EGJkDuWYWr4QQ2Hsp6+22KIpSSqmUAmOU1sYYImLmMPF9n1L6kac+1hqIKvbSAhFR3/fDMDRNQ0TxD79lRKprQaS8Z2Y+HA5d1yHiXbJzrhQlGIiBT+PxfBrPp3G/v2/ZWpvvRFHq2A7v46U7fvTjxe39/3IIQRTF21bkla3Al8DZDl4F1Aqaxt1edZM/AceSuqUJqAM5AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f90a224680a47323e8895cf697faecd6/e4706/Firefox-Clean-History_03.avif 230w", "/en/static/f90a224680a47323e8895cf697faecd6/d1af7/Firefox-Clean-History_03.avif 460w", "/en/static/f90a224680a47323e8895cf697faecd6/c9875/Firefox-Clean-History_03.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f90a224680a47323e8895cf697faecd6/a0b58/Firefox-Clean-History_03.webp 230w", "/en/static/f90a224680a47323e8895cf697faecd6/bc10c/Firefox-Clean-History_03.webp 460w", "/en/static/f90a224680a47323e8895cf697faecd6/d00b9/Firefox-Clean-History_03.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f90a224680a47323e8895cf697faecd6/81c8e/Firefox-Clean-History_03.png 230w", "/en/static/f90a224680a47323e8895cf697faecd6/08a84/Firefox-Clean-History_03.png 460w", "/en/static/f90a224680a47323e8895cf697faecd6/5a190/Firefox-Clean-History_03.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f90a224680a47323e8895cf697faecd6/5a190/Firefox-Clean-History_03.png",
              "alt": "Erase your Browsing History in Mozilla Firefox",
              "title": "Erase your Browsing History in Mozilla Firefox",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Check all options and click on Clear Now.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      